<script setup>
import { ref } from 'vue';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import Button from 'primevue/button';
import RadioButton from 'primevue/radiobutton';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import FileUpload from 'primevue/fileupload';
import ProgressBar from 'primevue/progressbar';
import Badge from 'primevue/badge';
import ProgressSpinner from 'primevue/progressspinner';
import BlockUI from 'primevue/blockui';
import Checkbox from 'primevue/checkbox';
import Select from 'primevue/select';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { usePrimeVue } from 'primevue/config';
import axios from '../Axios.js';
import router from '../router/index.js';

const $primevue = usePrimeVue();
const toast = useToast();
const totalSize = ref(0);
const totalSizePercent = ref(0);
const files = ref([]);
const companyNameWarning = ref('');
const loading = ref(false);
const newVentType = ref('');
const newPenetrationType = ref('');
const newLayerType = ref('');

const schema = yup.object().shape({
    companyName: yup.string().required('Company Name is required'),
    accountNumber: yup.string().required('Account Number is required'),
    requestType: yup.string().required('Request Type is required'),
    repName: yup.string().required('Representative Name is required'),
    repPhone: yup.string().required('Representative Phone is required'),
    repEmail: yup.string().email('Invalid email').required('Representative Email is required'),
    hoName: yup.string().required('Homeowner Name is required'),
    hoAddress: yup.string().required('Homeowner Street Address is required'),
    hoCity: yup.string().required('Homeowner City is required'),
    hoState: yup.string().required('Homeowner State is required'),
    hoZip: yup.string().matches(/^\d{5}$/, 'Invalid ZIP code').required('Homeowner ZIP is required'),
    imageLink: yup.string().nullable(),
    fileFormat: yup.string().required('File Format is required'),
    insuranceCompany: yup.string().required('Insurance Company is required'),
    claimNumber: yup.string().required('Claim Number is required'),
    shingleType: yup.string().required('Shingle Type is required'),
    shingleTypeOther: yup.string().when('shingleType', {
        is: 'Other',
        then: (schema) => schema.required('Shingle Type (Other) is required'),
        otherwise: (schema) => schema.nullable()
    }),
    workScopes: yup.array().of(yup.string()),
    vents: yup.object(),
    penetrations: yup.object(),
    layers: yup.object(),
    otherTrades: yup.array().of(yup.string()),
    otherTradesOther: yup.string().when('otherTrades', {
        is: (value) => Array.isArray(value) && value.includes('Other'),
        then: (schema) => schema.required('Other Trades (Specify) is required'),
        otherwise: (schema) => schema.nullable()
    }),
    details: yup.string().required('Details are required'),
    gutterType: yup.string().when('workScopes', {
        is: (val) => val && val.includes('Add Gutters'),
        then: (schema) => schema.required('Gutter type is required'),
    }),
    gutterSize: yup.string().when('workScopes', {
        is: (val) => val && val.includes('Add Gutters'),
        then: (schema) => schema.required('Gutter size is required'),
    }),
    downspoutSize: yup.string().when('workScopes', {
        is: (val) => val && val.includes('Add Gutters'),
        then: (schema) => schema.required('Downspout size is required'),
    }),
    gutterLinearFeet: yup.number().when('workScopes', {
        is: (val) => val && val.includes('Add Gutters'),
        then: (schema) => schema.required('Gutter linear feet is required').min(0, 'Must be a positive number'),
    }),
    downspoutLinearFeet: yup.number().when('workScopes', {
        is: (val) => val && val.includes('Add Gutters'),
        then: (schema) => schema.required('Downspout linear feet is required').min(0, 'Must be a positive number'),
    }),
    splashGuards: yup.number().when('workScopes', {
        is: (val) => val && val.includes('Add Gutters'),
        then: (schema) => schema.required('Number of splash guards is required').min(0, 'Must be a positive number'),
    }),
    gutterAccessories: yup.string().when('workScopes', {
        is: (val) => val && val.includes('Add Gutters'),
        then: (schema) => schema.required('Please specify if there are additional gutter accessories'),
    }),
});

const { handleSubmit, values, defineInputBinds, errors, setFieldValue } = useForm({
    validationSchema: schema,
    initialValues: {
        companyName: '',
        accountNumber: '',
        requestType: 'Estimate Only',
        repName: '',
        repPhone: '',
        repEmail: '',
        hoName: '',
        hoAddress: '',
        hoCity: '',
        hoState: '',
        hoZip: '',
        imageLink: '',
        fileFormat: 'Xactimate',
        insuranceCompany: '',
        claimNumber: '',
        shingleType: '',
        shingleTypeOther: '',
        workScopes: [],
        vents: {
            ridge: 0,
            power: 0,
            box: 0,
            turbine: 0
        },
        penetrations: {
            pipeboot: 0,
            chimney: 0,
            skylight: 0
        },
        layers: {
            shingles: 0,
            materials: 0
        },
        otherTrades: [],
        otherTradesOther: '',
        details: '',
        gutterType: '',
        gutterSize: '',
        downspoutSize: '',
        gutterLinearFeet: null,
        downspoutLinearFeet: null,
        splashGuards: null,
        gutterAccessories: 'No',
    }
});

// Create validated fields for each form input
const companyName = defineInputBinds('companyName');
const accountNumber = defineInputBinds('accountNumber');
const repName = defineInputBinds('repName');
const repPhone = defineInputBinds('repPhone');
const repEmail = defineInputBinds('repEmail');
const requestType = defineInputBinds('requestType');
const hoName = defineInputBinds('hoName');
const hoAddress = defineInputBinds('hoAddress');
const hoCity = defineInputBinds('hoCity');
const hoState = defineInputBinds('hoState');
const hoZip = defineInputBinds('hoZip');
const imageLink = defineInputBinds('imageLink');
const fileFormat = defineInputBinds('fileFormat');
const insuranceCompany = defineInputBinds('insuranceCompany');
const claimNumber = defineInputBinds('claimNumber');
const shingleType = defineInputBinds('shingleType');
const shingleTypeOther = defineInputBinds('shingleTypeOther');
const workScopes = defineInputBinds('workScopes');
const otherTrades = defineInputBinds('otherTrades');
const otherTradesOther = defineInputBinds('otherTradesOther');
const details = defineInputBinds('details');

const onRemoveTemplatingFile = (file, removeFileCallback, index) => {
    removeFileCallback(index);
    totalSize.value -= parseInt(formatSize(file.size));
    totalSizePercent.value = totalSize.value / 10;
};

const onClearTemplatingUpload = (clear) => {
    clear();
    totalSize.value = 0;
    totalSizePercent.value = 0;
};

const onSelectedFiles = (event) => {
    files.value = event.files;
    files.value.forEach((file) => {
        totalSize.value += parseInt(formatSize(file.size));
    });
    totalSizePercent.value = totalSize.value / 10;
};

const formatSize = (bytes) => {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const addVentType = () => {
    console.log(newVentType.value);

    if (newVentType.value && !values.vents[newVentType.value]) {
        setFieldValue(`vents.${newVentType.value}`, 0);
        newVentType.value = '';
    }
};

const addPenetrationType = () => {
    if (newPenetrationType.value && !values.penetrations[newPenetrationType.value]) {
        console.log(newPenetrationType.value);

        setFieldValue(`penetrations.${newPenetrationType.value}`, 0);
        newPenetrationType.value = '';
    }
};

const addLayerType = () => {
    if (newLayerType.value && !values.layers[newLayerType.value]) {
        console.log(newLayerType.value);

        setFieldValue(`layers.${newLayerType.value}`, 0);
        newLayerType.value = '';
    }
};

const checkCompanyName = async (event) => {
    const value = event.target.value;

    if (value && value.length > 0) {
        try {
            const response = await axios.post('/verify-company-name', {
                companyName: value
            });
            const data = await response.data;

            if (!data.exists) {
                companyNameWarning.value = '<span class="text-red-500 font-bold">Company not found. Please check the name and try again.</span>';
            } else {
                companyNameWarning.value = '';
            }
        } catch (error) {
            console.error('Error checking company name:', error);
            companyNameWarning.value = '<span class="text-red-500 font-bold">Error verifying company name. Please try again.</span>';
        }
    }
};

const checkAccountNumber = async (event) => {
    const value = event.value;

    if (value && value.length === 13) {
        try {
            const response = await axios.post('/verify-account-number', {
                accountNumber: value
            });
            const data = await response.data;

            if (!data.exists) {
                errors.value.accountNumber = 'Account number not found. Please check and try again.';
            } else {
                errors.value.accountNumber = null;
            }
        } catch (error) {
            console.error('Error checking account number:', error);
            errors.value.accountNumber = 'Error verifying account number. Please try again.';
        }
    }
};

const submit = handleSubmit(async (values) => {
    loading.value = true;

    const formDataToSubmit = new FormData();

    for (const [key, value] of Object.entries(values)) {
        if (Array.isArray(value)) {
            value.forEach((item, index) => {
                formDataToSubmit.append(`${key}[${index}]`, item);
            });
        } else if (typeof value === 'object' && value !== null) {
            for (const [subKey, subValue] of Object.entries(value)) {
                formDataToSubmit.append(`${key}[${subKey}]`, subValue);
            }
        } else {
            formDataToSubmit.append(key, value);
        }
    }

    if (files.value.length) {
        files.value.forEach((file) => {
            formDataToSubmit.append('files[]', file);
        });
    }

    try {
        const response = await axios.post('/service-request', formDataToSubmit, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        const data = await response.data;
        console.log(data);

        loading.value = false;

        toast.add({
            summary: 'Success',
            severity: 'success',
            detail: 'Service request submitted successfully',
            life: 5000
        });

        await router.push({
            name: 'success',
            params: {
                requestId: data.requestId
            }
        });
    } catch (error) {
        loading.value = false;
        console.error('Error:', error);

        toast.add({
            summary: 'Error',
            severity: 'error',
            detail: 'Failed to submit service request',
            life: 5000
        });

        await router.push({
            name: 'error',
            params: {
                title: 'Error',
                subtitle: 'Service Request Submission Failed',
                message: 'An error occurred while submitting your service request. Please try again later.'
            }
        });
    }
});
</script>

<template>
    <div class="card flex justify-center">
        <div class="flex gap-2 flex-col mt-5 w-8/12 lg:w-10/12">
            <div class="bg-primary-200 font-medium p-8" style="margin-bottom: -10px">
                <h1 class="text-3xl mb-5">Service Request</h1>
                <p>
                    Please submit all applicable documents, files, and photos for the service, or services requested to
                    the best of your ability. If changes are required please reach out to us at
                    sales@claim-connection.com.
                </p>
            </div>

            <!-- Company and Representative Information -->
            <div class="rounded-b-2xl bg-surface-100 font-medium p-8">
                <div class="flex flex-wrap gap-5">
                    <div class="flex flex-col gap-2 w-96">
                        <label for="company">Company Name</label>
                        <InputText id="companyName"
                                   v-bind="companyName"
                                   @focusout="checkCompanyName"
                                   :invalid="!!errors.companyName"
                                   aria-describedby="company-help"
                        />
                        <small id="company-help">Company name on your account</small>
                        <small v-if="errors.companyName" class="text-red-500">
                            {{ errors.companyName }}
                        </small>
                        <small v-if="companyNameWarning" v-html="companyNameWarning"></small>
                    </div>
                    <div class="flex flex-col gap-2 w-96">
                        <label for="account-number" class="block">Account Number</label>
                        <InputMask id="account-number"
                                   mask="99999"
                                   placeholder="99999"
                                   fluid
                                   :invalid="!!errors.accountNumber"
                                   aria-describedby="account-number-help"
                                   @focusout="checkAccountNumber"
                                   @update:modelValue="setFieldValue('accountNumber', $event)"
                                   :modelValue="values.accountNumber"
                        />
                        <small id="account-number-help">Your 5 digit account number</small>
                        <small v-if="errors.accountNumber" class="text-red-500">
                            {{ errors.accountNumber }}
                        </small>
                    </div>
                </div>

                <div class="flex flex-wrap gap-5 mt-10">
                    <div class="flex flex-col gap-2 w-96">
                        <label for="rep-name">Rep Name</label>
                        <InputText id="rep-name"
                                   v-bind="repName"
                                   :invalid="!!errors.repName"
                                   aria-describedby="rep-name-help"
                        />
                        <small id="rep-name-help">Your Full Name</small>
                        <small v-if="errors.repName" class="text-red-500">
                            {{ errors.repName }}
                        </small>
                    </div>
                    <div class="flex flex-col gap-2">
                        <label for="rep-phone" class="block">Rep Phone</label>
                        <InputMask id="rep-phone"
                                   mask="(999) 999-9999? x9999"
                                   placeholder="(999) 999-9999"
                                   fluid
                                   :invalid="!!errors.repPhone"
                                   aria-describedby="rep-phone-help"
                                   @update:modelValue="setFieldValue('repPhone', $event)"
                                   :modelValue="values.repPhone"
                        />
                        <small id="rep-phone-help">Your direct phone number</small>
                        <small v-if="errors.repPhone" class="text-red-500">
                            {{ errors.repPhone }}
                        </small>
                    </div>
                    <div class="flex flex-col gap-2 w-4/12">
                        <label for="rep-email">Rep Email</label>
                        <InputText id="rep-email"
                                   v-bind="repEmail"
                                   :invalid="!!errors.repEmail"
                                   aria-describedby="rep-email-help"
                        />
                        <small id="rep-email-help">Your email address</small>
                        <small v-if="errors.repEmail" class="text-red-500">
                            {{ errors.repEmail }}
                        </small>
                    </div>
                </div>
            </div>

            <!-- File Upload and Request Details -->
            <div class="rounded-2xl bg-surface-100 font-medium p-8">
                <div class="flex flex-wrap gap-5">
                    <div class="flex flex-col gap-5 w-full">
                        <div class="flex flex-col gap-2">
                            <label for="file-upload">All Relevant Documents</label>
                            <FileUpload name="files[]"
                                        :multiple="true"
                                        :maxFileSize="10000000"
                                        @select="onSelectedFiles"
                                        @clear="onClearTemplatingUpload"
                            >
                                <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
                                    <div class="flex flex-wrap justify-between items-center flex-1 gap-4">
                                        <ProgressBar :value="totalSizePercent" :showValue="false"
                                                     class="md:w-20rem h-1 w-full md:ml-auto">
                                            <span class="whitespace-nowrap">{{ totalSize }} / 10MB</span>
                                        </ProgressBar>
                                    </div>
                                </template>
                                <template
                                    #content="{ files, uploadedFiles, removeUploadedFileCallback, removeFileCallback }">
                                    <div class="flex flex-col gap-8 pt-4">
                                        <div v-if="files.length > 0">
                                            <h5>Pending</h5>
                                            <div class="flex flex-wrap gap-4">
                                                <div v-for="(file, index) of files"
                                                     :key="file.name + file.type + file.size"
                                                     class="p-8 rounded-border flex flex-col border border-surface items-center gap-4">
                                                    <span
                                                        class="font-semibold text-ellipsis max-w-60 whitespace-nowrap overflow-hidden">
                                                        {{ file.name }}
                                                    </span>
                                                    <div>{{ formatSize(file.size) }}</div>
                                                    <Badge value="Pending" severity="warn" />
                                                    <Button icon="pi pi-times"
                                                            @click="onRemoveTemplatingFile(file, removeFileCallback, index)"
                                                            outlined rounded severity="danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template #empty>
                                    <div class="flex items-center justify-center flex-col">
                                        <i class="pi pi-cloud-upload !border-2 !rounded-full !p-8 !text-4xl !text-muted-color" />
                                        <p class="mt-6 mb-0">Drag and drop files here.</p>
                                    </div>
                                </template>
                            </FileUpload>
                            <small id="file-upload-help">
                                Up to 5 documents to support the supplement (Including Hover for siding, iTEL for
                                discontinued shingles or siding and sub bids for Skylights, Metal Work and Masonry)
                                Be sure to label/name each file as to what the contents are.
                            </small>
                        </div>

                        <div class="flex flex-wrap gap-5 mt-10">
                            <div class="flex flex-col gap-2 w-64">
                                <label for="request-type">Request Type</label>
                                <Select id="request-type"
                                        :options="['Estimate Only', 'Estimate & Supplement']"
                                        placeholder="Select One"
                                        :invalid="!!errors.requestType"
                                        :modelValue="values.requestType"
                                        @change="setFieldValue('requestType', $event.value)"
                                />
                                <small id="request-type-help">Type of request</small>
                                <small v-if="errors.requestType" class="text-red-500">
                                    {{ errors.requestType }}
                                </small>
                            </div>
                            <div class="flex flex-col gap-2 w-64">
                                <label for="file-format">File Format</label>
                                <Select id="file-format"
                                        :options="['Xactimate', 'Symbility']"
                                        placeholder="Select One"
                                        :invalid="!!errors.fileFormat"
                                        :modelValue="values.fileFormat"
                                        @change="setFieldValue('fileFormat', $event.value)"
                                />
                                <small id="file-format-help">File format for estimate</small>
                                <small v-if="errors.fileFormat" class="text-red-500">
                                    {{ errors.fileFormat }}
                                </small>
                            </div>
                        </div>

                        <div class="flex flex-wrap gap-5">
                            <div class="flex flex-col gap-2 w-80">
                                <label for="insurance-company">Insurance Company</label>
                                <InputText id="insurance-company"
                                           v-bind="insuranceCompany"
                                           :invalid="!!errors.insuranceCompany"
                                           aria-describedby="insurance-company-help"
                                />
                                <small id="insurance-company-help">Insurance Company Name</small>
                                <small v-if="errors.insuranceCompany" class="text-red-500">
                                    {{ errors.insuranceCompany }}
                                </small>
                            </div>

                            <div class="flex flex-col gap-2">
                                <label for="claim-number">Claim Number</label>
                                <InputText id="claim-number"
                                           v-bind="claimNumber"
                                           :invalid="!!errors.claimNumber"
                                           aria-describedby="claim-number-help"
                                />
                                <small id="claim-number-help">Insurance Claim Number</small>
                                <small v-if="errors.claimNumber" class="text-red-500">
                                    {{ errors.claimNumber }}
                                </small>
                            </div>
                        </div>

                        <div class="flex flex-wrap gap-5">
                            <div class="flex flex-col gap-2 w-8/12">
                                <label for="image-link">Image Link</label>
                                <InputText id="image-link"
                                           v-bind="imageLink"
                                           :invalid="!!errors.imageLink"
                                           aria-describedby="image-link-help"
                                />
                                <small id="image-link-help">Link to images (optional)</small>
                                <small v-if="errors.imageLink" class="text-red-500">
                                    {{ errors.imageLink }}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Homeowner Information -->
            <div class="rounded-2xl bg-surface-100 font-medium p-8">
                <div class="flex flex-wrap gap-5">
                    <div class="flex flex-col gap-2 w-5/12">
                        <label for="ho-name">Homeowner Name</label>
                        <InputText id="ho-name"
                                   v-bind="hoName"
                                   :invalid="!!errors.hoName"
                                   aria-describedby="ho-name-help"
                        />
                        <small id="ho-name-help">Homeowner's Full Name</small>
                        <small v-if="errors.hoName" class="text-red-500">
                            {{ errors.hoName }}
                        </small>
                    </div>
                    <div class="flex flex-col gap-2 w-96">
                        <label for="ho-address">Homeowner Address</label>
                        <InputText id="ho-address"
                                   v-bind="hoAddress"
                                   :invalid="!!errors.hoAddress"
                                   aria-describedby="ho-address-help"
                        />
                        <small id="ho-address-help">Homeowner's Street Address</small>
                        <small v-if="errors.hoAddress" class="text-red-500">
                            {{ errors.hoAddress }}
                        </small>
                    </div>
                </div>

                <div class="flex flex-wrap gap-5 mt-5">
                    <div class="flex flex-col gap-2">
                        <label for="ho-city">Homeowner City</label>
                        <InputText id="ho-city"
                                   v-bind="hoCity"
                                   :invalid="!!errors.hoCity"
                                   aria-describedby="ho-city-help"
                        />
                        <small id="ho-city-help">Homeowner's City</small>
                        <small v-if="errors.hoCity" class="text-red-500">
                            {{ errors.hoCity }}
                        </small>
                    </div>

                    <div class="flex flex-col gap-2">
                        <label for="ho-state">Homeowner State</label>
                        <InputText id="ho-state"
                                   v-bind="hoState"
                                   :invalid="!!errors.hoState"
                                   aria-describedby="ho-state-help"
                        />
                        <small id="ho-state-help">Homeowner's State</small>
                        <small v-if="errors.hoState" class="text-red-500">
                            {{ errors.hoState }}
                        </small>
                    </div>

                    <div class="flex flex-col gap-2">
                        <label for="ho-zip">Homeowner ZIP</label>
                        <InputMask id="ho-zip"
                                   mask="99999"
                                   placeholder="99999"
                                   fluid
                                   :invalid="!!errors.hoZip"
                                   aria-describedby="ho-zip-help"
                                   @update:modelValue="setFieldValue('hoZip', $event)"
                                   :modelValue="values.hoZip"
                        />
                        <small id="ho-zip-help">Homeowner's ZIP code</small>
                        <small v-if="errors.hoZip" class="text-red-500">
                            {{ errors.hoZip }}
                        </small>
                    </div>
                </div>
            </div>

            <!-- Roof and Property Details -->
            <div class="rounded-2xl bg-surface-100 font-medium p-8">
                <div class="flex gap-5">
                    <div class="flex flex-col gap-5">
                        <div class="flex flex-col gap-2 w-72">
                            <label for="shingle-type">What is the current roofing material?</label>
                            <Select id="shingle-type"
                                    :options="['Asphalt - 3-Tab', 'Asphalt - Dimensional', 'Cedar Shake or Like', 'Synthetic', 'Tile', 'Metal', 'Other']"
                                    placeholder="Select One"
                                    :invalid="!!errors.shingleType"
                                    :modelValue="values.shingleType"
                                    @change="setFieldValue('shingleType', $event.value)"
                            />
                            <small v-if="errors.shingleType" class="text-red-500">
                                {{ errors.shingleType }}
                            </small>
                        </div>

                        <div v-if="values.shingleType === 'Other'" class="flex flex-col gap-2 w-1/2">
                            <label for="shingle-type-other">Shingle Type (Other)</label>
                            <InputText id="shingle-type-other"
                                       v-bind="shingleTypeOther"
                                       :invalid="!!errors.shingleTypeOther"
                                       aria-describedby="shingle-type-other-help"
                            />
                            <small id="shingle-type-other-help">Specify shingle type if Other</small>
                            <small v-if="errors.shingleTypeOther" class="text-red-500">
                                {{ errors.shingleTypeOther }}
                            </small>
                        </div>
                    </div>
                    <div class="flex flex-col gap-5">
                        <div class="flex flex-col gap-2 w-72">
                            <label for="shingle-type">What are you installing on the roof?</label>
                            <Select id="shingle-type"
                                    :options="['Asphalt - 3-Tab', 'Asphalt - Dimensional', 'Cedar Shake or Like', 'Synthetic', 'Tile', 'Metal', 'Other']"
                                    placeholder="Select One"
                                    :invalid="!!errors.shingleType"
                                    :modelValue="values.shingleType"
                                    @change="setFieldValue('shingleType', $event.value)"
                            />
                            <small v-if="errors.shingleType" class="text-red-500">
                                {{ errors.shingleType }}
                            </small>
                        </div>

                        <div v-if="values.shingleType === 'Other'" class="flex flex-col gap-2 w-1/2">
                            <label for="shingle-type-other">Shingle Type (Other)</label>
                            <InputText id="shingle-type-other"
                                       v-bind="shingleTypeOther"
                                       :invalid="!!errors.shingleTypeOther"
                                       aria-describedby="shingle-type-other-help"
                            />
                            <small id="shingle-type-other-help">Specify shingle type if Other</small>
                            <small v-if="errors.shingleTypeOther" class="text-red-500">
                                {{ errors.shingleTypeOther }}
                            </small>
                        </div>
                    </div>
                </div>


                <div class="flex flex-wrap gap-10 mt-10">
                    <div class="flex flex-col gap-2">
                        <div class="text-xl">Vents</div>
                        <small id="vents-help">Select the number of each vent type</small>
                        <div class="flex items-center gap-2 mt-2">
                            <InputText class="h-10" v-model="newVentType" placeholder="New Vent Type" />
                            <Button size="small" label="Add" @click="addVentType" />
                        </div>
                        <div v-for="(vent, index) in Object.keys(values.vents)"
                             :key="index"
                             class="flex items-center justify-end"
                        >
                            <label class="pr-5" :for="vent">{{ vent }}</label>
                            <InputNumber id="vent"
                                         :modelValue="values.vents[vent]"
                                         @update:modelValue="setFieldValue(`vents.${vent}`, $event)"
                            />
                        </div>

                        <small v-if="errors.vents" class="text-red-500">{{ errors.vents }}</small>
                    </div>

                    <div class="flex flex-col gap-2">
                        <div class="text-xl">Penetrations</div>
                        <small id="penetrations-help">Select the number of each penetration type</small>
                        <div class="flex items-center gap-2 mt-2">
                            <InputText class="h-10" v-model="newPenetrationType" placeholder="New Penetration Type" />
                            <Button size="small" label="Add" @click="addPenetrationType" />
                        </div>
                        <div v-for="(penetration, index) in Object.keys(values.penetrations)"
                             :key="index"
                             class="flex items-center justify-end"
                        >
                            <label class="pr-2" :for="penetration">{{ penetration }}</label>
                            <InputNumber id="penetration"
                                         :modelValue="values.penetrations[penetration.toLowerCase().replace(' ', '')]"
                                         @update:modelValue="setFieldValue(`penetrations.${penetration.toLowerCase().replace(' ', '')}`, $event)"
                            />
                        </div>
                        <small v-if="errors.penetrations" class="text-red-500">{{ errors.penetrations }}</small>
                    </div>

                    <div class="flex flex-col gap-2">
                        <div class="text-xl">Layers</div>
                        <small id="layers-help">Select the number of each layer type</small>
                        <div class="flex items-center gap-2 mt-2">
                            <InputText class="h-10" v-model="newLayerType" placeholder="New Layer Type" />
                            <Button size="small" label="Add" @click="addLayerType" />
                        </div>
                        <div v-for="(layer, index) in Object.keys(values.layers)"
                             :key="index"
                             class="flex items-center justify-end"
                        >
                            <label class="pr-2" :for="layer">{{ layer }}</label>
                            <InputNumber id="layer"
                                         :modelValue="values.layers[layer.toLowerCase()]"
                                         @update:modelValue="setFieldValue(`layers.${layer.toLowerCase()}`, $event)"
                            />
                        </div>
                        <small v-if="errors.layers" class="text-red-500">{{ errors.layers }}</small>
                    </div>
                </div>

                <div class="flex flex-wrap gap-5 mt-10">
                    <div class="flex flex-col gap-2">
                        <div class="text-xl">Add the selected to the work scope</div>
                        <small id="work-scopes-help">Material required by codes will automatically be added!</small>
                        <div class="flex flex-wrap gap-5">
                            <div
                                v-for="type in ['Add Drip Edge', 'Add Ice & Water Shield', 'Add Gutters']"
                                :key="type"
                                class="flex items-center"
                            >
                                <Checkbox :inputId="type"
                                          name="work-scopes"
                                          :value="type"
                                          :modelValue="values.workScopes"
                                          @update:modelValue="setFieldValue('workScopes', $event)"
                                          aria-describedby="work-scopes-help"
                                />
                                <label class="ml-2" :for="type">{{ type }}</label>
                            </div>
                        </div>
                        <small v-if="errors.workScopes" class="text-red-500">{{ errors.workScopes }}</small>
                    </div>
                </div>

                <!-- New Gutter Details Section -->
                <div v-if="values.workScopes.includes('Add Gutters')" class="mt-10">
                    <div class="text-xl mb-5">Gutter Details</div>

                    <div class="flex flex-wrap gap-5 mb-5">
                        <div class="flex flex-col gap-2 w-96">
                            <label for="gutter-type">What type of gutters are currently on the house?</label>
                            <Select id="gutter-type"
                                    :options="['Aluminium', 'Copper', 'Other']"
                                    placeholder="Select One"
                                    :modelValue="values.gutterType"
                                    @change="setFieldValue('gutterType', $event.value)"
                            />
                        </div>

                        <div class="flex flex-col gap-2 w-72">
                            <label for="gutter-size">What is the size of gutters?</label>
                            <Select id="gutter-size"
                                    :options="['5in', '6in']"
                            placeholder="Select One"
                            :modelValue="values.gutterSize"
                            @change="setFieldValue('gutterSize', $event.value)"
                            />
                        </div>
                    </div>

                    <div class="flex flex-wrap gap-5 mb-5">
                        <div class="flex flex-col gap-2 w-72">
                            <label for="downspout-size">What is the size of the downspouts?</label>
                            <Select id="downspout-size"
                                    :options="['2in x 3in', '3in x 4in']"
                            placeholder="Select One"
                            :modelValue="values.downspoutSize"
                            @change="setFieldValue('downspoutSize', $event.value)"
                            />
                        </div>
                    </div>

                    <div class="flex flex-wrap gap-5 mb-5">
                        <div class="flex flex-col gap-2 w-72">
                            <label for="gutter-linear-feet">What is the linear feet(LF) of the gutters?</label>
                            <InputNumber id="gutter-linear-feet"
                                         :modelValue="values.gutterLinearFeet"
                                         @update:modelValue="setFieldValue('gutterLinearFeet', $event)"
                            />
                        </div>

                        <div class="flex flex-col gap-2 w-72">
                            <label for="downspout-linear-feet">What is the linear feet(LF) of the downspouts?</label>
                            <InputNumber id="downspout-linear-feet"
                                         :modelValue="values.downspoutLinearFeet"
                                         @update:modelValue="setFieldValue('downspoutLinearFeet', $event)"
                            />
                        </div>
                    </div>

                    <div class="flex flex-wrap gap-5 mb-5">
                        <div class="flex flex-col gap-2 w-72">
                            <label for="splash-guards">How many splash guards are currently on the house?</label>
                            <InputNumber id="splash-guards"
                                         :modelValue="values.splashGuards"
                                         @update:modelValue="setFieldValue('splashGuards', $event)"
                            />
                        </div>
                    </div>

                    <div class="flex flex-wrap gap-5">
                        <div class="flex flex-col gap-2">
                            <label for="gutter-accessories">Are there additional accessories installed on the gutters?</label>
                            <div class="flex items-center gap-4">
                                <div class="flex items-center">
                                    <RadioButton id="gutter-accessories-yes"
                                                 name="gutter-accessories"
                                                 value="Yes"
                                                 :modelValue="values.gutterAccessories"
                                                 @update:modelValue="setFieldValue('gutterAccessories', $event)"
                                    />
                                    <label for="gutter-accessories-yes" class="ml-2">Yes</label>
                                </div>
                                <div class="flex items-center">
                                    <RadioButton id="gutter-accessories-no"
                                                 name="gutter-accessories"
                                                 value="No"
                                                 :modelValue="values.gutterAccessories"
                                                 @update:modelValue="setFieldValue('gutterAccessories', $event)"
                                    />
                                    <label for="gutter-accessories-no" class="ml-2">No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="flex flex-col gap-2 mt-10">
                    <div class="text-xl">Other Trades (Bid Estimate Required)</div>
                    <small id="other-trades-help">Select the other trades that will be involved</small>
                    <div class="flex flex-wrap gap-5">
                        <div
                            v-for="(trade, index) in ['Masonry', 'Wood Siding', 'Skylights', 'Stucco', 'Vinyl Siding', 'Other']"
                            :key="index"
                            class="flex items-center"
                        >
                            <Checkbox :inputId="trade"
                                      name="other-trades"
                                      :value="trade"
                                      :modelValue="values.otherTrades"
                                      @update:modelValue="setFieldValue('otherTrades', $event)"
                                      aria-describedby="other-trades-help"
                            />
                            <label class="ml-2" :for="trade">{{ trade }}</label>
                        </div>
                    </div>
                    <small v-if="errors.otherTrades" class="text-red-500">{{ errors.otherTrades }}</small>
                </div>

                <div class="mt-10" v-if="values.otherTrades.includes('Other')">
                    <div class="flex flex-col gap-2 w-1/2">
                        <label for="other-trades-other">Specify</label>
                        <InputText id="other-trades-other"
                                   v-bind="otherTradesOther"
                                   :invalid="!!errors.otherTradesOther"
                                   aria-describedby="other-trades-other-help"
                        />
                        <small id="other-trades-other-help">Specify trades</small>
                        <small v-if="errors.otherTradesOther" class="text-red-500">
                            {{ errors.otherTradesOther }}
                        </small>
                    </div>
                </div>

                <div class="flex flex-col gap-2 mt-10 w-1/2">
                    <label for="details">Claim/Supplement Information</label>
                    <Textarea id="details"
                              v-bind="details"
                              :invalid="!!errors.details"
                              aria-describedby="details-help"
                    />
                    <small id="details-help">Important Details/Notes</small>
                    <small v-if="errors.details" class="text-red-500">
                        {{ errors.details }}
                    </small>
                </div>
            </div>

            <!-- SUBMIT -->
            <div class="mt-5 mb-10 flex justify-end">
                <Button label="Submit" @click="submit" :loading="loading" />
            </div>
        </div>
    </div>

    <Toast position="top-center" />
    <BlockUI :blocked="loading" fullScreen />
    <div v-if="loading" class="fixed top-0 left-0 w-full h-full">
        <div class="flex justify-center items-center h-full">
            <ProgressSpinner />
        </div>
    </div>
</template>
